<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Authentication Mode</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                There are three modes available to manage authentication for your storefront:
                            </p>
                            <ul>
                                <li><strong>Connect</strong> - authentication is managed by your own website, and when you redirect customers to Unicorn Springs you identify the customer account and user; this entails more development work on your website but gives you complete control over the sign up and sign in process for your customers</li>
                                <li><strong>Delegate</strong> - you can present a simple link to redirect customers to Unicorn Springs, where sign up &amp; sign in will be handled for you; this is the default option and saves you a lot of time and effort compared to the other options because Unicorn Springs has excellent customer account and user management built-in</li>
                                <li><strong>SSO</strong> - use an external, 3rd party single sign-on (SSO) to manage authentication; both your website and Unicorn Springs will use the same provider to authenticate users; SSO is only available with the enterprise add-on</li>
                            </ul>

                            <template v-if="isViewReady">
                                <p class="mb-0 pb-0 mt-6">
                                    Authentication mode: <strong>{{ authenticationModeDisplay }}</strong>
                                    <v-btn icon color="green darken-2" @click="dialogEditAuthenticationMode = true">
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                    </v-btn>
                                </p>
                                <p class="mb-0 pb-0 mt-2" v-if="isAuthenticationModeConnect">
                                    <EditableText :value="authenticationExternalURL" label="Authentication URL" @input="saveAuthenticationExternalURL" dense/>
                                </p>
                                <!-- <template v-if="isAuthenticationModeDelegate">
                                    TODO: button to start webauthz request to logifnront to set up the realm
                                </template> -->
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-if="isAuthenticationModeSSO">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                    <font-awesome-icon :icon="['fas', 'cog']" class="grey--text text--darken-2 mr-2"></font-awesome-icon>
                                    Single sign-on
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="font-size: 0.85em;">
                                <!-- <p class="mb-0 pb-0 mt-2"> -->
                                <v-row no-gutters class="mb-0 pb-0 mt2-">
                                    <EditableText :value="loginfrontRealm" label="LoginFront Realm" @input="saveLoginFrontRealm" dense/>
                                </v-row>
                                <!-- </p> -->
                                <!-- <p class="mb-0 pb-0 mt-2"> -->
                                <v-row no-gutters class="mb-0 pb-0 mt2-">
                                    <EditableText :value="loginfrontClientTokenDisplay" label="LoginFront Client Token" @input="saveLoginFrontClientToken" dense/>
                                    <template v-if="isPermitServiceAdmin">
                                        <v-dialog v-model="adminDisplayLoginfrontClientToken">
                                            <template #activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" color="amber darken-2">
                                                    <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    LoginFront Client Token
                                                    <v-spacer/>
                                                    <span class="red--text">
                                                    CONFIDENTIAL
                                                    <!-- <font-awesome-icon :icon="['fas', 'lock']" class="ml-2"></font-awesome-icon> -->
                                                    </span>
                                                </v-card-title>
                                                <v-card-text class="pt-6">
                                                    <v-textarea outlined :value="secretSettingValue['loginfront_client_token']"></v-textarea>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </template>
                                </v-row>
                                <!-- </p> -->
                                <!-- <p class="mb-0 pb-0 mt-2"> -->
                                <v-row no-gutters class="mb-0 pb-0 mt2-">
                                    <EditableText :value="loginfrontAccessTokenDisplay" label="LoginFront Access Token" @input="saveLoginFrontAccessToken" dense/>
                                    <template v-if="isPermitServiceAdmin">
                                        <v-dialog v-model="adminDisplayLoginfrontAccessToken">
                                            <template #activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" color="amber darken-2">
                                                    <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    LoginFront Access Token
                                                    <v-spacer/>
                                                    <span class="red--text">
                                                    CONFIDENTIAL
                                                    <!-- <font-awesome-icon :icon="['fas', 'lock']" class="ml-2"></font-awesome-icon> -->
                                                    </span>
                                                </v-card-title>
                                                <v-card-text class="pt-6">
                                                    <v-textarea outlined :value="secretSettingValue['loginfront_access_token']"></v-textarea>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </template>
                                </v-row>
                                <!-- </p> -->
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-dialog v-model="dialogEditAuthenticationMode" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Set the authentication mode</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>This change will take effect immediately. Please ensure your website is ready.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editAuthenticationMode" @keyup.enter.native="editAuthenticationMode" class="px-5 mt-5">
                                <v-select v-model="editableAuthenticationMode" :items="authenticationModeChoices" outlined dense>
                                </v-select>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="editAuthenticationMode" :disabled="!isEditAuthenticationModeFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditAuthenticationMode = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
import { AUTHENTICATION_MODE_CONNECT, AUTHENTICATION_MODE_DELEGATE, AUTHENTICATION_MODE_SSO } from '@/sdk/constants';

export default {
    components: {
        EditableText,
    },
    data: () => ({
        organization: null,
        authenticationMode: null,
        authenticationExternalURL: null,
        error: null,
        isViewReady: false,
        authenticationModeChoices: [
            { text: 'Connect', value: AUTHENTICATION_MODE_CONNECT },
            { text: 'Delegate', value: AUTHENTICATION_MODE_DELEGATE },
            { text: 'SSO', value: AUTHENTICATION_MODE_SSO }, // TODO: only show this when enterpise add-on is available for purchase (even if customer doesn't have it yet -- selecting this should  prompt them to upgrade)
        ],
        dialogEditAuthenticationMode: false,
        editableAuthenticationMode: null,
        submitFormTimestamp: null,
        // for delegate only
        loginfrontRealm: null,
        loginfrontClientTokenDisplay: null,
        loginfrontAccessTokenDisplay: null,
        // for admin only
        secretSettingValue: {},
        adminDisplayLoginfrontClientToken: false,
        adminDisplayLoginfrontAccessToken: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditAuthenticationModeFormComplete() {
            // TODO: if editableAuthenticationMode === 'sso' and organization doesn't have the enterprise add-on, we need to show a dialog that says they need that add-on to use sso, and a button to purchase it; if they cancel the dialog, the editable authentication mode should revert to its previous value
            return this.editableAuthenticationMode;
        },
        authenticationModeDisplay() {
            if (this.authenticationMode) {
                const choice = this.authenticationModeChoices.find((item) => item.value === this.authenticationMode);
                if (choice) {
                    return choice.text;
                }
            }
            return 'Set the authentication mode';
        },
        isAuthenticationModeConnect() {
            return this.authenticationMode === AUTHENTICATION_MODE_CONNECT;
        },
        isAuthenticationModeDelegate() {
            return this.authenticationMode === AUTHENTICATION_MODE_DELEGATE;
        },
        isAuthenticationModeSSO() {
            return this.authenticationMode === AUTHENTICATION_MODE_SSO;
        },
    },
    watch: {
        dialogEditAuthenticationMode(newValue) {
            if (newValue) {
                this.editableAuthenticationMode = this.authenticationMode;
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadSetting(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    if (response.is_secret && response.secret) {
                        console.log(`loadSetting secret value: ${name}`);
                        this.$set(this.secretSettingValue, name, response.secret);
                    }
                    return response.content;
                }
                return null;
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async loadAuthenticationMode() {
            this.authenticationMode = await this.loadSetting('authentication_mode');
            if (this.authenticationMode === AUTHENTICATION_MODE_CONNECT) {
                await this.loadAuthenticationExternalURL();
            }
            if (this.authenticationMode === AUTHENTICATION_MODE_SSO) {
                await this.loadLoginFrontSettings();
            }
        },
        async loadAuthenticationExternalURL() {
            this.authenticationExternalURL = await this.loadSetting('authentication_external_url');
        },
        async loadLoginFrontSettings() {
            this.loginfrontRealm = await this.loadSetting('loginfront_realm');
            this.loginfrontClientTokenDisplay = await this.loadSetting('loginfront_client_token');
            this.loginfrontAccessTokenDisplay = await this.loadSetting('loginfront_access_token');
        },
        async editAuthenticationMode() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editAuthenticationMode: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'authentication_mode' }, { content: this.editableAuthenticationMode });
                console.log(`editAuthenticationMode: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditAuthenticationMode = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadAuthenticationMode();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit authentication mode' });
                }
            } catch (err) {
                console.error('failed to edit authentication mode', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit authentication mode' });
            } finally {
                this.$store.commit('loading', { editAuthenticationMode: false });
            }
        },
        async saveOranizationSetting(settingName, newValue, settingDisplayText) {
            try {
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { saveOranizationSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: settingName }, { content: newValue });
                console.log(`saveOranizationSetting: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                } else {
                    const headline = settingDisplayText ? `Failed to edit ${settingDisplayText}` : `Failed to edit setting: ${settingName}`;
                    this.$bus.$emit('snackbar', { type: 'error', headline });
                }
                return response?.isEdited;
            } catch (err) {
                console.error(`failed to edit setting ${settingName}`, err);
                const headline = settingDisplayText ? `Failed to edit ${settingDisplayText}` : `Failed to edit setting: ${settingName}`;
                this.$bus.$emit('snackbar', { type: 'error', headline });
                return false;
            } finally {
                this.$store.commit('loading', { saveOranizationSetting: false });
            }
        },
        async saveAuthenticationExternalURL(newValue) {
            const isEdited = await this.saveOranizationSetting('authentication_external_url', newValue, 'authentication external URL');
            if (isEdited) {
                this.authenticationExternalURL = newValue;
            }
        },
        /**
         * Server will only allow system admin to set this directly; organization admins must use webauthz with LoginFront to set it up
         */
        async saveLoginFrontRealm(newValue) {
            const isEdited = await this.saveOranizationSetting('loginfront_realm', newValue, 'LoginFront realm');
            if (isEdited) {
                this.loginfrontRealm = newValue;
            }
        },
        /**
         * Server will only allow system admin to set this directly; organization admins must use webauthz with LoginFront to set it up
         */
        async saveLoginFrontClientToken(newValue) {
            if (newValue === '********') {
                return;
            }
            const isEdited = await this.saveOranizationSetting('loginfront_client_token', newValue, 'LoginFront client token');
            if (isEdited) {
                this.loginfrontClientTokenDisplay = '********';
            }
        },
        /**
         * Server will only allow system admin to set this directly; organization admins must use webauthz with LoginFront to set it up
         */
        async saveLoginFrontAccessToken(newValue) {
            if (newValue === '********') {
                return;
            }
            const isEdited = await this.saveOranizationSetting('loginfront_access_token', newValue, 'LoginFront access token');
            if (isEdited) {
                this.loginfrontAccessTokenDisplay = '********';
            }
        },
        async init() {
            await this.loadAuthenticationMode();
            this.isViewReady = true;
        },
    },
    mounted() {
        this.loadOrganization(); // TODO: remove when we have organization maintained by vuex store.js and app.vue
        this.init();
    },
};
</script>
